import React, { useState, useEffect } from 'react'
import { useLocation } from '@reach/router'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { FaEnvelope, FaPhoneAlt, FaChevronDown, FaBars } from 'react-icons/fa'

import * as styles from './Navbar.module.scss'

const Navbar = ({studentPortalLink, nextStepLinks, siteLogo}) => {
  const [showMenu, setShowMenu] = useState(false)
  const [ aboutExpanded, setAboutExpanded ] = useState(false)
  const [ registrationsExpanded, setRegistrationsExpanded ] = useState(false)
  const [ newsExpanded, setNewsExpanded ] = useState(false)
  const location = useLocation()

  // CLOSES MOBILE MENU ON ROUTE CHANGE
  useEffect(() => {
    setShowMenu(false)
  }, [location.pathname])

  const toggleMobileMenu = () => {
    setShowMenu(prevShowMenu => !prevShowMenu)
  }

  return (
    <>
      <div className={styles.mobileContactLinks}>
        <a href="mailto:info@mass.edu.za"><FaEnvelope />&nbsp; <span>info@mass.edu.za</span></a>
        <a href="tel:015 964 4101"><FaPhoneAlt />&nbsp; <span>015 964 4101</span></a>
      </div>
      <nav className={styles.navbar}>
        <div className={styles.navbarContainer}>
          
          <Link to='/' className={styles.logoContainer}>
          <GatsbyImage 
              image={getImage(siteLogo.asset)}
              imgStyle={{ objectFit: 'contain', width: '100%', height: '100%' }}
              alt="MassCollege" 
              className={styles.logoImage} 
            />
          </Link>

          <div className={`${styles.navbarContent} ${showMenu ? styles.showMenu : ''}`}>
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', gap: '1rem' }}>
              <a href="mailto:info@mass.edu.za"><FaEnvelope />&nbsp; <span>info@mass.edu.za</span></a>
              <a href="tel:015 964 4101"><FaPhoneAlt />&nbsp; <span>015 964 4101</span></a>
              {studentPortalLink && (
                <a href={studentPortalLink} target='_blank' className={styles.btnNav} id='student-login-btn'>Student Login</a>
              )}
            </div>

            <ul>
              <li><span>About <FaChevronDown onClick={() => setAboutExpanded(!aboutExpanded)}/></span>
                <ul className={`${styles.dropdownList} ${aboutExpanded ? styles.showDropdown : styles.dontShow}`}>
                  <li><Link to='/about/'>About Us</Link></li>
                  <li><Link to='/our-team/'>Our Team</Link></li>
                  <li><Link to='/accreditations/'>Our Accreditations</Link></li>
                </ul>
              </li>

              <li className={styles.coursesItem}><Link to='/courses/'>Courses</Link></li>

              <li><span>Registrations <FaChevronDown onClick={() => setRegistrationsExpanded(!registrationsExpanded)}/></span>
                <ul className={`${styles.dropdownList} ${registrationsExpanded ? styles.showDropdown : styles.dontShow}`}>
                  <li><Link href='/contact'>Enquire</Link></li>
                  {nextStepLinks && nextStepLinks.length > 0 && (
                    <li>
                      {nextStepLinks.find(link => link.text === 'Apply Now') && (
                        <Link href={nextStepLinks.find(link => link.text === 'Apply Now').link.href} target='_blank'>
                          {nextStepLinks.find(link => link.text === 'Apply Now').text}
                        </Link>
                      )}
                    </li>
                  )}
                </ul>
              </li>

              <li><Link to='/our-campus/'>Our Campus</Link></li>

              <li><span><Link to='/news/'>News</Link><FaChevronDown onClick={() => setNewsExpanded(!newsExpanded)}/></span>
                <ul className={`${styles.dropdownList} ${newsExpanded ? styles.showDropdown : styles.dontShow}`}>
                  <li><Link to='/news?category=Campus%20News'>Campus News</Link></li>
                  <li><Link to='/news?category=Career%20%26%20Study%20Advice'>Career & Study Advice</Link></li>
                  <li><Link to='/news?category=Student%20Success%20Stories'>Student Success Stories</Link></li>
                </ul>
              </li>
              <li><Link to='/contact/'>Contact</Link></li>
            </ul>
          </div>
          <button onClick={toggleMobileMenu} className={styles.menuBtn}>
            <FaBars />
          </button>
        </div>
      </nav>
    </>
  )
}

export default Navbar